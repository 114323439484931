<template>
  <div class="pageContent">
    <!-- 全屏轮播图 -->
    <!-- <div class="fullWrap">
      <fullScreen></fullScreen>
    </div> -->
    <header class="head_content">
      <img class="bigImg" src="../assets/images/home/head_bgImage.png" alt="" />
      <div class="head_box">
        <div class="head_left">
          <div class="headText">
            <h1>Préstamos Personales</h1>
            <h3>100% en Línea, Seguro y Fiable</h3>
          </div>
          <div class="jumpBtn">¡Solicítalo hoy!</div>
        </div>
        <!-- <div class="head_right">
          <img src="../assets/images/home/head_rightImage.png" alt="" />
        </div> -->
      </div>
    </header>
    <main class="main_content" id="mainContent">
      <div class="main_content_content">
        <h2>¡Obtén tu dinero al instante!</h2>
        <div class="main_content_textLine"></div>
      </div>
      <ul>
        <li v-for="(item, index) in mainListArr" :key="index">
          <div><img :src="item.icon" alt="" /></div>
          <h5>{{ item.title }}</h5>
          <h6>{{ item.content }}</h6>
        </li>
      </ul>
    </main>
    <main class="main_box">
      <div class="main_box_content">
          <div class="right_text">
          <h6 style="text-indent: -5px">
            CICI Préstamos se especializa en brindar préstamos en efectivo
            convenientes y seguros para los peruanos y brinda un sólido apoyo
            crediticio a cientos de miles de peruanos con la necesidad urgente de
            un préstamo.
          </h6>
          <h6 style="margin-top: 20px; text-indent: -5px">
            Si después de solicitar y obtener su préstamo personal paga a tiempo o
            antes de la fecha de pago acordada, su límite de crédito aumentará
            hasta un máximo de 10,000 soles.
          </h6>
        </div>
        <div class="left_img">
          <img src="../assets/images/home/main_box_img.png" alt="" />
        </div>
      </div>
    </main>
    <main class="main_end">
      <h2>¡EN 3 SIMPLES PASOS!</h2>
       <ul>
        <li v-for="(item, index) in endArr" :key="index">
          <div><img :src="item.icon" alt="" /></div>
          <h5>{{ item.title }}</h5>
          <h6>{{ item.content }}</h6>
        </li>
      </ul>
    </main>
    <article class="article_content">
      <img
        class="article_bgImg"
        src="../assets/images/home/article_bgImg.png"
        alt=""
      />
      <!-- <div class="article_rightImg">
        <img src="../assets/images/home/article_rightImg.png" alt="" />
      </div> -->
      <div class="article_box">
        <div class="text_content">
          <h2>Preguntas frecuentes</h2>
          <h4 style="margin-top: 40px">Mi cuenta</h4>
          <h6 style="margin-top: 10px; text-indent: -4px">
            1. ¿Cómo puedo acceder a los préstamos de CICI Préstamos?
          </h6>
          <h6 style="margin-top: 8px">2. ¿Cómo cambio mis datos personales?</h6>
          <h6 style="margin-top: 8px">3. ¿Qué redes admite CICI Préstamos?</h6>
          <h4 style="margin-top: 30px">Criterios de elegibilidad</h4>
          <h6 style="margin-top: 10px; text-indent: -4px">
            1. ¿Puedo cambiar mi DNI después de recibir un préstamo?
          </h6>
          <h6 style="margin-top: 8px">2. ¿Puedo eliminar mi cuenta?</h6>
        </div>
        <div
          class="apply_btn"
          @click="goTo({ label: 'FAQ', name: 'About', to: '/about' })"
        >
          Aprende más
        </div>
      </div>
    </article>
    <!-- <section class="section_content">
      <h2>Nuestros usuarios quieren compartir contigo</h2>
      <div class="swiper-container" id="swiper1" style="width:100%">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in swiperList"
            :key="index"
          >
            <div style="text-align: center; margin-top: 18px">
              <img :src="item.img" class="avatar" />
            </div>
            <p class="comment_text">
              {{ item.comment }}
            </p>
            <div class="comment_star_box">
              <img src="../assets/images/home/comment_star.png" alt="" />
              <img src="../assets/images/home/comment_star.png" alt="" />
              <img src="../assets/images/home/comment_star.png" alt="" />
              <img src="../assets/images/home/comment_star.png" alt="" />
              <img src="../assets/images/home/comment_star.png" alt="" />
              <img src="../assets/images/home/comment_star.png" alt="" />
            </div>
            <div class="name_box">{{ item.name }}</div>
          </div>
        </div>

        <div class="swiper_button_left swiper-button-prev" slot="button-prev">
          <img src="../assets/images/home/swiper_left_button.png" alt="" />
        </div>
        <div class="swiper_button_right swiper-button-next" slot="button-next">
          <img src="../assets/images/home/swiper_right_button.png" alt="" />
        </div>
      </div>
    </section> -->
  </div>
</template>
<script>
import { onMounted, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import Swiper, { Autoplay, EffectCoverflow, Navigation } from "swiper";
Swiper.use([Autoplay, EffectCoverflow, Navigation]);
import { useRoute, useRouter } from "vue-router";

export default {
  components: {},
  setup() {
    const routeName = ref("");
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const addCount = ref(1500);
    const currentRate = ref(0);
    const rate = computed(() => store.state.walking.rate);
    watch(
      () => route.name,
      (_new) => {
        routeName.value = _new;
      },
      {
        immediate: true,
      }
    );
    const addRange = () => {
      currentRate.value += Number(addCount.value);
    };
    const culRange = () => {
      currentRate.value -= Number(addCount.value);
      currentRate.value = currentRate.value < 0 ? 0 : currentRate.value;
    };
    const mainListArr = [
      {
        icon: require("../assets/images/home/main_icon1.png"),
        title: "SIN GARANTÍA",
        content: "No necesitas pagar ninguna tarifa por pedir un prestamo.",
      },
      {
        icon: require("../assets/images/home/main_icon2.png"),
        title: "MÁS CONVENIENTE",
        content:
          "Solicite financiación de forma rápida y sencilla desde cualquier lugar.",
      },
      {
        icon: require("../assets/images/home/main_icon3.png"),
        title: "MÁS SEGURO",
        content: "Todos tus datos están protegidos siempre.",
      },
      {
        icon: require("../assets/images/home/main_icon4.png"),
        title: "EN 10 MINUTOS",
        content: "Recíbelo en tu cuenta bancaria una vez aprovado.",
      },
    ];
    const endArr = [
      {
        icon: require("../assets/images/home/card_box1.png"),
        title: "Solicita Ahora",
        content: "Completa tu solicitud en nuestra aplicación en menos de 5 minutos.",
      },
      {
        icon: require("../assets/images/home/card_box2.png"),
        title: "Verifica tu Identidad",
        content:
          "Verifica tu identidad a través de tu DNI, cuenta bancaria o mediante tu banca en línea.",
      },
      {
        icon: require("../assets/images/home/card_box3.png"),
        title: "Obtén tu Préstamo",
        content: "Recibe el dinero en tu cuenta bancaria en cuestión de minutos u horas.",
      },
    ];
    const swiperList = [
      {
        img: require("../assets/images/home/avtar1.png"),
        comment:
          "Muy buena porque te presta rápido. Le doy muy buena calificación ya que depositan rapido. Excelente servicio. La recomiento a todos . Al 100 % seguro y fácil.",
        name: "Jesika Roxana",
      },
      {
        img: require("../assets/images/home/avtar2.png"),
        comment:
          "Es muy buena , interés bajo, y tiene mas días de plazo que las otras app, deposita esta al instante , la recomiendo. ",
        name: "Lesly Yanela",
      },
      {
        img: require("../assets/images/home/avtar3.png"),
        comment:
          "Muy agradecida con esta app, me sacó de apuros. Todo fue bastante rápido, pagué con anticipación y me permitió sacar otro préstamo al instante. Recomendada al 100% y completamente confiable 🤗",
        name: "Melissa YP",
      },
      {
        img: require("../assets/images/home/avtar4.png"),
        comment:
          "Me gusta la aplicación, cumple lo que promete, supongo que los créditos los da en base a como estás en el sistema financiero.",
        name: "Rafael Guado",
      },
      {
        img: require("../assets/images/home/avtar5.png"),
        comment:
          "Bueno para mi es una APP que te saca de apuros obviamente, te piden que seas muy responsable y cumplido hasta la fecha mis préstamos son inmediatos y sin mucha demora el desembolso.",
        name: "Walter Ruiz",
      },
      {
        img: require("../assets/images/home/avtar6.png"),
        comment:
          "Excelente!!! te transfiere en 1 hora y si pagas puntual aumenta tu credito. Y eso que estoy en infocorp me saco de un apuro.",
        name: "Chris Gutierrez",
      },
    ];
    const goTo = (item) => {
      router.push({ path: item.to });
    };
    //获取swiper对象
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    let coverflowEffect = {
      rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
      stretch: -50, //每个slide之间的拉伸值，越大slide靠得越紧。
      depth: 100, //slide的位置深度。值越大z轴距离越远，看起来越小。
      modifier: 1, //depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
      slideShadows: false, //开启slide阴影。默认 true。
    };
    const swiperOptionInfo = {
      direction: "horizontal",
      speed: 600,
      loop: true,
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      observer: true,
      observeParents: true,
      effect: "coverflow",
      slidesPerView: "auto",
      centeredSlides: true,
      navigation: {
        nextEl: ".swiper_button_right",
        prevEl: ".swiper-button-prev",
      },
      coverflowEffect: {
        rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
        stretch: -1000, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
        depth: 50, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
        modifier: 50,
        slideShadows: false, //开启slide阴影。默认 true。
      },
    };
    onMounted(() => {
      new Swiper("#swiper1", {
        direction: "horizontal",
        speed: 600,
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        observer: true,
        observeParents: true,
        effect: "coverflow",
        slidesPerView: "auto",
        centeredSlides: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        coverflowEffect: {
          rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
          stretch: 0, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
          depth: 50, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
          modifier: 50,
          slideShadows: false, //开启slide阴影。默认 true。
        },
      });
    });

    return {
      addRange,
      culRange,
      currentRate,
      mainListArr,
      endArr,
      swiperList,
      onSwiper,
      swiperOptionInfo,
      coverflowEffect,
      goTo,
    };
  },
};
</script>
<style lang="scss" scoped>
.bg {
  background-color: #eee;
}
.head_content {
  position: relative;
  min-width: 1440px;

  // background: url("~@/assets/images/home/head_bgImage.png") no-repeat;
  // background-size: 100% 100%;
  // background-attachment: fixed;
  .bigImg {
    width: 100%;
    height: 655px;
  }
  .head_box {
    width: 1020px;
    position: absolute;
    top: 58px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    .head_left {
      .headText {
        margin-top: 200px;
        h1 {
          color: rgba(255, 255, 255, 1);
          font-family: Source Han Sans CN;
          font-weight: bold;
          font-size: 42px;
          word-break: break-word;
          white-space: break-spaces;
        }
        h3 {
          color: rgba(255, 255, 255, 1);
          font-family: PingFang SC;
          font-weight: 600;
          font-size: 20px;
          word-break: break-word;
          white-space: break-spaces;
        }
      }
      .jumpBtn {
        width: 230px;
        height: 54px;
        text-align: center;
        line-height: 54px;
        border-radius: 14px;
        background: rgb(51, 85, 255);
        margin-top: 90px;
        cursor: pointer;
        color: #fff;
        font-family: PingFang SC;
        font-weight: 600;
        font-size: 20px;
      }
    }
    .head_right {
      width: 554px;
      height: 500px;
      img {
        width: 554px;
        height: 500px;
      }
    }
  }
}
.main_content {
  width: 1020px;
  margin: 0 auto;
  margin-top: 45px;
  .main_content_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    .main_content_textLine{
      margin: auto;
      width: 81px;
      height: 3px;
      border: 3px solid rgba(51, 85, 255, 1);
      box-sizing: border-box;
      border-radius: 4px;
    }
  }

  h2 {
    text-align: center;
    color: rgb(51, 51, 51);
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 32px;
    word-break: break-word;
    white-space: break-spaces;
  }
  ul {
    list-style: none;
    width: 100%;
    margin: 0 auto;
    margin-top: 50px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    li {
      width: 23%;
      height: 226px;
      margin-right: 12px;
      border-radius: 10px;
      // background: rgba(255, 245, 239, 1);
      div {
        width: 60px;
        height: 60px;
        margin: 0 auto;
        margin-top: 25px;
        img {
          width: 80px;
          height: 80px;
        }
      }
      h5 {
        text-align: center;
        color: rgba(51, 51, 51, 1);
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-size: 20px;
        margin-top: 35px;
        word-break: break-word;
        white-space: break-spaces;
      }
      h6 {
        width: 88%;
        margin: 0 auto;
        word-break: break-word;
        white-space: break-spaces;
        text-align: center;
        color: rgba(102, 102, 102, 1);
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 14px;
        margin-top: 10px;
        line-height: 21px;
        word-break: break-word;
        white-space: break-spaces;
      }
    }
  }
}
.main_box {
  width: 100%;
  height: 409px;
  margin: 0 auto;
  margin-top: 80px;
  background: url('../assets/images/home/main_content_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .main_box_content{
    height: 100%;
    width: 1020px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left_img {
      width: 330px;
      height: 379px;
      img {
        width: 330px;
        height: 379px;
      }
    }
    .right_text {
      width: 406px;
      h6 {
        color: #fff;
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        word-break: break-word;
        white-space: break-spaces;
      }
    }
  }
}
.main_end {
  width: 1020px;
  margin: 0 auto;
  margin-top: 45px;
  .main_content_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    .main_content_textLine{
      margin: auto;
      width: 81px;
      height: 3px;
      border: 3px solid rgba(51, 85, 255, 1);
      box-sizing: border-box;
      border-radius: 4px;
    }
  }

  h2 {
    text-align: center;
    color: rgb(51, 51, 51);
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 32px;
    word-break: break-word;
    white-space: break-spaces;
  }
  ul {
    list-style: none;
    width: 100%;
    margin: 0 auto;
    margin-top: 50px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    li {
      width: 30%;
      height: 301px;
      // margin-right: 12px;
      background: rgb(230, 239, 249);
      div {
        width: 60px;
        height: 60px;
        margin: 0 auto;
        margin-top: 50px;
        img {
          width: 80px;
          height: 80px;
        }
      }
      h5 {
        text-align: center;
        color: rgba(51, 51, 51, 1);
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-size: 20px;
        margin-top: 35px;
        word-break: break-word;
        white-space: break-spaces;
      }
      h6 {
        width: 88%;
        margin: 0 auto;
        word-break: break-word;
        white-space: break-spaces;
        text-align: center;
        color: rgba(102, 102, 102, 1);
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 14px;
        margin-top: 10px;
        line-height: 21px;
        word-break: break-word;
        white-space: break-spaces;
      }
    }
  }
}
.article_content {
  width: 100%;
  min-width: 1440px;
  position: relative;
  margin-top: 80px;
  .article_bgImg {
    width: 100%;
    height: 500px;
  }
  .article_box {
    position: absolute;
    width: 520px;
    top: 54px;
    right: -150px;
    transform: translateX(-50%);
    .text_content {
      font-size: 0px;
      h2 {
        color: rgba(61, 83, 127, 1);
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-size: 32px;
        word-break: break-word;
        white-space: break-spaces;
      }
      h4 {
        color: rgba(51, 51, 51, 1);
        font-family: SourceHanSansCN-Heavy;
        font-weight: bold;
        font-size: 20px;
        word-break: break-word;
        white-space: break-spaces;
      }
      h6 {
        color: rgba(92, 93, 92, 1);
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        word-break: break-word;
        white-space: break-spaces;
      }
    }
    .apply_btn {
      margin-top: 20px;
      width: 119px;
      height: 31px;
      text-align: center;
      line-height: 31px;
      border-radius: 8px;
      background: linear-gradient(316.32deg, rgba(51, 85, 255, 1) 0%, rgba(77, 166, 255, 1) 97.8%);;
      color: rgba(255, 255, 255, 1);
      font-family: Roboto;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
.section_content {
  width: 100%;
  min-width: 1000px;
  position: relative;
  margin-top: 62px;
  h2 {
    text-align: center;
    color: rgba(61, 83, 127, 1);
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 32px;
    word-break: break-word;
    white-space: break-spaces;
  }
  #swiper1 {
    width: 96%;
    min-width: 1200px;
    height: 480px;
    margin: 0 auto;
    margin-top: 20px;
    padding-top: 35px;
    position: relative;
    overflow: hidden;
  }
  .swiper-wrapper {
    display: -webkit-inline-box;
  }
  .swiper-slide {
    width: 20% !important;
    height: 307px;
    margin-left: 15px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 14px rgba(0, 21, 28, 0.18);
    position: relative;
  }
  .avatar {
    width: 71px;
    height: 70px;
  }
  .comment_text {
    width: 95%;
    margin: 0 auto;
    margin-top: 13px;
    text-align: center;
    color: rgba(92, 93, 92, 1);
    font-family: Source Han Sans CN;
    font-size: 14px;
    word-break: break-word;
    white-space: break-spaces;
    line-height: 20px;
  }

  .comment_star_box {
    width: 80%;
    position: absolute;
    text-align: center;
    bottom: 49px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 24px;
      height: 24px;
    }
  }
  .name_box {
    position: absolute;
    text-align: center;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    color: rgba(0, 0, 0, 1);
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 16px;
  }
  .swiper-slide-active {
    height: 361px !important;
    margin-top: -30px;
  }
  .swiper-slide-active .comment_text {
    margin-top: 33px !important;
  }
}
.swiper_button {
  width: 20%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  div {
    width: 49px;
    height: 50px;
  }
  img {
    width: 49px;
    height: 50px;
  }
}
.swiper-button-prev:after {
  display: none;
}

.swiper-button-next:after {
  display: none;
}
.swiper-button-prev {
  position: absolute;
  width: 49px;
  height: 50px;
  left: 45%;
  top: 88%;
  cursor: pointer;

  img {
    width: 49px;
    height: 50px;
  }
}
.swiper-button-next {
  position: absolute;
  width: 49px;
  height: 50px;
  right: 45%;
  top: 88%;
  cursor: pointer;
  img {
    width: 49px;
    height: 50px;
  }
}
.fullWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
