<template>
  <footer class="footer_content" id="contentUs">
    <div class="color_bar">
      <h2>Solicita tu Préstamo Online</h2>
      <h6>
        Una solicitud más ágil, complétala hoy y obtén hasta S/ 10,000 sin salir
        de casa.
      </h6>
    </div>
    <div class="footer_box">
      <div class="footer_detail">
        <div class="footer_logo">
          <img src="../assets/logo.png" alt="" style="height:42px" />
        </div>
        <div class="footer_text">
          <div style="width:50%;">
            <h4>Contáctanos</h4>
            <div class="contact">
              <span
                ><img src="../assets/images/home/email_icon.png" alt=""
              /></span>
              <p
                style="cursor: pointer"
                @click="goTo('mailto:ciciprestamos@gmail.com')"
              >
                ciciprestamos@gmail.com
              </p>
            </div>
          </div>
          <div style="width:40%">
            <h4
              style="cursor: pointer"
              @click="goTo('https://ciciprestamos.com/terminos-y-condiciones')"
            >
              Términos y Condiciones
            </h4>
            <h4
              style="cursor: pointer"
              @click="goTo('https://ciciprestamos.com/politica-de-privacidad')"
            >
              Política de Privacidad
            </h4>
          </div>
        </div>
        <div class="footer_copyRight">GLOBAL FINANCE PARTNERS S.A.C. © CICI Préstamos 2023</div>
      </div>
    </div>
  </footer>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "FFooter",
  setup(params, ctx) {
    const goPost = () => {
      const doc = document.documentElement || document.body;
      let distance = doc.scrollTop; //获得当前高度
      (function jump() {
        let step = distance / 5; //每步的距离
        if (distance > 0) {
          distance -= step;
          distance = distance < 1 ? 0 : distance;
          window.scrollTo(0, distance);
          window.requestAnimationFrame(jump);
        }
      })();
    };
    const goTo = (href) => {
      // window.location.href = href;
      window.open(href, "_blank");
    };
    return {
      goPost,
      goTo,
    };
  },
};
</script>
<style lang="scss" scoped>
.footer_content {
  width: 100%;
  min-width: 1000px;
  position: relative;
  margin-top: 80px;
  .color_bar {
    width: 100%;
    height: 131px;
    background: linear-gradient(316.32deg, rgba(51, 85, 255, 1) 0%, rgba(77, 166, 255, 1) 97.8%);
    padding-top: 30px;
    h2 {
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 32px;
    }
    h6 {
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
    }
  }
  .footer_box {
    width: 100%;
    height: 280px;
    background: url('../assets/images/home/footer_bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .footer_detail {
      width: 1020px;
      margin: 0 auto;
      padding-top: 30px;
      .footer_logo {
        width: 250px;
        height: 33px;
        img {
          width: 250px;
          height: 33px;
        }
      }
      .footer_text {
        width: 100%;
        margin-top: 35px;
        display: flex;
        justify-content: space-between;
        h4 {
          color: #fff;
          font-family: Alibaba PuHuiTi;
          font-weight: bold;
          font-size: 18px;
          line-height: 30px;
        }
        .contact {
          margin-top: 8px;
          span {
            display: inline-block;
            width: 28px;
            height: 26px;
            vertical-align: middle;
            margin-right: 10px;
            img {
              width: 26px;
              height: 26px;
            }
          }
          p {
            display: inline-block;
            color: #fff;
            font-family: Alibaba PuHuiTi;
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
      .footer_copyRight {
        text-align: center;
        color: #fff;
        font-family: Alibaba PuHuiTi;
        font-size: 14px;
        margin-top: 32px;
      }
    }
  }
}
</style>
